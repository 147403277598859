<template>
  <div class="offer-items pt-4 pb-8 w-full" v-if="order">
    <vs-row class="block border-bottom-light pb-4">
      <p>Positionstyp: Überschrift</p>
      <p class="offer-items-offer-title">
        Abschlussrechnung zu Angebot #{{order.invoice_number}} - {{order.title}} vom {{formatDate(order.order_date)}}
      </p>
    </vs-row>
    <vs-row class="block border-bottom-light pb-4 ">
      <div class="position-container pt-4" v-if="billingOrderItems">
        <div v-for="(item,index) in billingOrderItems" class="position mb-2">
          <div class="w-full flex">
            <div class="w-full" @dblclick="onClickBillingItemInlineEdit(index)">
              <template v-if="billingInlineEditIndex != index">
                <div class="name" v-html="item.product_name">
                  {{item.product_name}}
                </div>
                <span v-if="billingPromptAddOfferReference2ProductName" class="reference2offer"> - Lt. Angebot {{order.invoice_number}} vom  {{ formatDate(order.order_date) }}</span>
                <div class="clearfix"> </div>
                <div class="description" v-html="item.short_description" v-show="billingPromptShowArticleDescription" >
                  {{item.short_description}}
                </div>
                <vs-alert color="#ff0000" :active="item.article != null && item.article.offer_description.length > 4" icon="error" class="mb-4 small-alert" >
                  Hinweis: Alternativer Rechnungstext im Artikel vorhanden. <vs-button color="danger" size="small" @click="openAlternativBillingDescriptionPopup(item)">anzeigen</vs-button>
                </vs-alert>
              </template>
              <template v-else>
                <vs-input size="small" v-model="billingProductItemClone.product_name" class="w-full mb-2"></vs-input>
                <vue-editor :editorToolbar="toolbar" v-model="billingProductItemClone.short_description" class="mb-2"></vue-editor>
                <div class="flex justify-end">
                  <vs-button size="small" @click="cancelBillingItemInlineEdit">Abbrechen</vs-button>
                  <vs-button size="small" color="success" @click="saveBillingItemInlineEdit">Speichern</vs-button>
                </div>
              </template>
            </div>
            <div class="w-1/2">
              <div class="w-full flex">

                <div class="w-full" v-bind:class="[item.is_accounting_finished  ? 'accounting_finished' : '']">
                  <div class="w-full flex" v-if="item.add_to_accounting">
                    <vs-input class="w-3/5 mr-4"
                              label-placeholder="Abzurechnende Menge" v-model="item.amount" @change="changeAmountInput(item)" data-vv-as="Abzurechnende Menge" :name="'billable_amount_' + index" v-validate="'decimal'"  />

                    <div class="w-1/2 pt-6">
                      {{unitName(item.volume_unit)}}
                    </div>
                  </div>
                  <span class="text-danger text-sm clearfix" v-if="errors.has('billable_amount_'+ index)">{{ errors.first('billable_amount_'+ index) }}</span>
                  <span class="amount">
                                                (Angebotene Menge {{item.offer_amount}}  {{unitName(item.volume_unit)}})
                                            </span>
                  <div class="alreadyAccounting mt-3" v-if="item.accountings.length >= 1">
                    <h5>Bereits abgerechnet:</h5>
                    <vs-table :data="item.accountings" :hoverFlat="true" class="mt-1">
                      <template slot="thead">
                        <vs-th>
                          RE-Nr.
                        </vs-th>
                        <vs-th>
                          Menge
                        </vs-th>
                        <vs-th>
                          Einzelpreis
                        </vs-th>

                      </template>
                      <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="tr">
                            <div v-if="data[indextr].order">
                              {{data[indextr].order.invoice_number}} <br>  {{ formatDate(data[indextr].order.order_date) }}
                            </div>
                            <div v-if="!data[indextr].order">
                              <span class="mg-label">In der Abrechnung</span>
                            </div>
                          </vs-td>

                          <vs-td :data="tr.amount">
                            {{data[indextr].amount}} {{unitName(data[indextr].volume_unit)}}
                          </vs-td>

                          <vs-td :data="tr.unit_price">
                            {{data[indextr].unit_price}} €
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </div>
                <div class="w-2/5">
                  <vs-alert color="#8a8a8a" :active="item.is_accounting_finished == true || item.is_accounting_finished == 1" icon="warning" class="mb-4 small-alert" >
                    Diese Position wurde schon als "abgerechnet" markiert.
                  </vs-alert>

                  <vs-checkbox v-model="item.add_to_accounting" @change="changeAddToAccountingCheckbox(item)" v-if="!item.is_accounting_finished">Abrechnen</vs-checkbox>
<!--                  <vs-button icon="check_circle_outline" color="success" size="small" class="mt-4" v-if="!item.is_accounting_finished" @click="saveOrderAccountingFinished(item)">Bereits vollständig abgerechnet!</vs-button>-->
                  <div class=" ml-2 mt-4" v-if="item.is_accounting_finished">
                                                <span class="link" @click="saveOrderAccountingNotFinished(item)">
                                                    Als <b>nicht</b> vollständig abgerechnet markieren
                                                </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-row>
    <vs-row>
      <div class="mt-8">
        <div class="flex mb-4 pb-2 pt-2 subtotal">
          <div class="w-1/2">Position Zwischensumme</div>
          <div class="w-1/2 text-right">12080€</div>
        </div>
        <div class="flex pb-4">
          <vs-checkbox v-model="alreadyBeenBilledCheckbox" class="w-1/7"></vs-checkbox>
          <div class="w-1/2">Abzüglich bereits Abgerechnetes</div>
        </div>
        <div class="flex pb-4">
          <div class="w-1/4"> <vs-checkbox v-model="alreadyBeenBilledCheckbox"></vs-checkbox></div>
          <div class="w-full pl-2">-Abzüglich Rechnung 47270 - Teilrechung</div>
          <div class="w-1/4 text-right">4500€</div>
        </div>
        <div class="flex pb-4">
          <div class="w-1/4"> <vs-checkbox v-model="alreadyBeenBilledCheckbox"></vs-checkbox></div>
          <div class="w-full pl-2">-Abzüglich Rechnung 47270 - Teilrechung</div>
          <div class="w-1/4 text-right">4500€</div>
        </div>
        <div class="flex pb-4">
          <div class="w-1/4"> <vs-checkbox></vs-checkbox></div>
          <div class="w-full pl-2">-Abzüglich Rechnung 47270 - Teilrechung</div>
          <div class="w-1/4 text-right">4500€</div>
        </div>
        <div class="pt-4">
          <div class="flex billing-items pb-4">
            <span class="material-icons mr-4">warning</span>
            <span class="pt-1">Abrechnungspositionen in der Abrechnung zu diesem Angebot vorhanden</span>
          </div>
          <div class="draft-invoice flex pb-4">
            <span class="material-icons mr-4">check</span>
            <span class="pt-1">Keine "Entwurfsrechnung" vorhanden</span> </div>
          <div class="amount">Summe: 3480€</div>
        </div>
        <button type="button" @click="onSaveOrder" name="button" class="vs-component vs-button vs-button-success vs-button-filled mt-6"> Rechnung erstellen</button>
      </div>
    </vs-row>
  </div>
</template>
<script>

import ApiService from "../../../../../api";
import moment from 'moment';
import staticOptions from "../../../../../mixins/static/options";

export default {
  name: "OfferItems",
  components: {
  },
  data() {
    return {
      order: null,
      billingOrderItems: [],
      billingInlineEditIndex: null,
      billingPromptAddOfferReference2ProductName:true,
      billingPromptShowArticleDescription:true,
      alreadyBeenBilledCheckbox: true
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const id = this.$route.params.id;
      this.$vs.loading()
      ApiService.get(`orders/${id}`).then(response => {
        this.order = response.data.result;
        this.$vs.loading.close()
        return this.getOrderItems();
      }).catch(response => {
      })
    },
    onSaveOrder() {
      const id = this.$route.params.id;
      const postData = {
        title: this.order.title,
        short_description: this.order.short_description,
      };

      this.$vs.loading();

      ApiService.put(`orders/${id}`, postData).then(response => {
        if (response.status === 200) {
          this.$vs.loading.close();

          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Änderung wurde erfolgreich gespeichert',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }
      }).catch(response => {
        this.$vs.loading.close();

        this.$vs.notify({
          title: 'Fehler',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      });
    },
   formatDate() {
      return moment().format('DD.MM.YYYY')
   },
    getOrderItems() {
      this.billingOrderItems=[];
      for(let i = 0;i < this.order.items.length; i++) {
        if(this.order.items[i].type == 'article' || this.order.items[i].type == 'group'){

          var item = JSON.parse(JSON.stringify(this.order.items[i]))
          if(this.order.items[i].optional_item == 0 && this.order.items[i].is_accounting_finished == false) {
            item["add_to_accounting"] = true;
          } else {
            item["add_to_accounting"] = false;
          }
          item["offer_amount"] = item["amount"];

          if(this.order.items[i].is_accounting_finished == 1 || this.order.items[i].is_accounting_finished == true) {
            item["amount"] = null;
          }
          this.billingOrderItems.push(item)
        }
      }
    },
    unitName(value) {
      let name = staticOptions.units.find((item) => item.value == value);

      if(name === null || name === undefined) {
        return null;
      }

      return name.labelShort;
    },
  }
}
</script>
<style lang="scss">
.offer-items {
  .offer-items-offer-title {
    font-weight: bold;
  }
  .position {
    &:nth-of-type(even) {
      background: #efefef;
    }
    > .flex > div {
      padding: 15px;
    }

    .description {
      font-size: 12px;
      color: #a0a0a0;
      margin-top: 6px;

    }
    .accounting_finished {
      opacity: 0.3;
    }
    .name {
      display: inline;
    }
  }
  .subtotal {
    border-top: 1px solid #dccbcb;
    border-bottom: 1px solid #dccbcb;
  }
}
</style>
